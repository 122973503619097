import classNames from 'classnames';
import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Package } from '../../../modules/packages/types';
import PackageType from '../../PackageType/PackageType';

import classes from './SelectablePackageListItem.module.scss';

type Props = {
    package: Package;

    selected: boolean;
    onSelect: (arg1: number) => void;
};

function SelectablePackageListItem(props: Props) {
    const { package: pkg, selected, onSelect } = props;

    const className = classNames(classes.root, {
        [classes.selected]: selected
    });

    return (
        <label className={className}>
            <span className={classNames(classes.radio, 'tf-radio')}>
                <input
                    type="radio"
                    name="package"
                    value={pkg.id}
                    checked={selected}
                    onChange={() => onSelect(pkg.id)}
                />

                <span className={classNames(classes.radioLabel, 'tf-radio__label')}>
                    <span className="tf-radio__faux"></span>
                </span>
            </span>

            <span className={classes.label}>
                <span className={classes.text}>
                    <FormattedMessage
                        id="SELECTABLE_PACKAGE_LIST_ITEM.LABEL"
                        values={{
                            current: pkg.remaining_contingent,
                            total: pkg.total_contingent
                        }}
                    />
                </span>

                <span className={classes.indicator}>
                    <span className={classes.indicatorInner}>
                        <PackageType pkg={pkg} wrap />
                    </span>
                </span>
                <span className={classes.expiration}>
                    <FormattedMessage
                        id="SELECTABLE_PACKAGE_LIST_ITEM.EXPIRATION_LABEL"
                        values={{
                            expirationDate: <FormattedDate value={pkg.expiration} dateStyle="medium" />
                        }}
                    />
                </span>
            </span>
        </label>
    );
}

export default SelectablePackageListItem;
