import { isBefore, isEqual, parseISO } from 'date-fns';
import {
    GeocoderResult,
    getCoordinatesFromGeocoderResult,
    getCountryCodeFromGeocoderResult,
    getLocalityFromGeocoderResult,
    getPostalCodeFromGeocoderResult,
    getRegionFromGeocoderResult,
    getStreetNameFromGeocoderResult,
    getStreetNumberFromGeocoderResult
} from '../../services/maps/geocoder';
import { includes } from '../../utils/array';
import { omit } from '../../utils/object';
import { Recruiter, GalleryImage as RecruiterGalleryImage } from '../recruiters/types';
import { EXPERIENCES, nonFeedProviders } from './constants';

// Types
import { Job, JobExperience, JobGalleryImage, JobLocation, JobSubset } from './types';

/*
 * By job state
 */
export function isNew(job: Job): boolean {
    return job.id <= 0 || job.state === 'new';
}

export function isProcessing(job: Job): boolean {
    return job.state === 'processing';
}

export function isDraft(job: Job): boolean {
    return job.state === 'draft';
}

export function isActive(job: Job): boolean {
    return job.state === 'active';
}

export function isArchived(job: Job): boolean {
    return job.state === 'archived';
}

/*
 * Actions
 */

export function isEditable(job: Job): boolean {
    return (
        nonFeedProviders.includes(job.provider) ||
        (isFromFeed(job) && !isFromMirroredFeed(job) && isTrial(job)) ||
        isNew(job) ||
        isDraft(job)
    );
}

export const limitedEditableProperties: Array<keyof Job> = ['title', 'location', 'company', 'functions'];

export function isPropertyEditable(job: Job, propteryName: keyof Job): boolean {
    if (!isEditable(job)) {
        return false;
    }

    if (!isNew(job) && !isDraft(job)) {
        return !!propteryName && !limitedEditableProperties.includes(propteryName);
    }

    return true;
}

export function isArchivable(job: Job): boolean {
    return isActive(job);
}

export function isDeletable(job: Job): boolean {
    return isDraft(job) || isArchived(job);
}

export function isPublishable(job: Job): boolean {
    if (isFromMirroredFeed(job)) {
        return false;
    }

    return isDraft(job);
}

export function isProlongable(job: Job): boolean {
    if (isFromMirroredFeed(job)) {
        return false;
    }

    return isActive(job);
}

export function isReactivatable(job: Job): boolean {
    if (isFromMirroredFeed(job)) {
        return false;
    }

    return isArchived(job);
}

/*
 * Others
 */
export function isTrial(job: JobSubset<'is_premium'>): boolean {
    return !job.is_premium;
}

export function isFromFeed(job: JobSubset<'provider'>): boolean {
    return !nonFeedProviders.includes(job.provider);
}

export function isFromMirroredFeed(job: Parameters<typeof isFromFeed>[0] & JobSubset<'is_platinum_premium'>): boolean {
    return isFromFeed(job) && job.is_platinum_premium;
}

export function isExpired(job: Job, now: number | Date): boolean {
    if (!job.active_until) {
        return false;
    }

    const activeUntilDate = parseISO(job.active_until);

    return isBefore(activeUntilDate, now) || isEqual(activeUntilDate, now);
}

/**
 * Checks if a job has a valid active_until date. In some cases the active_until date is not valid because it changes
 * regularly – e.g. in case of a job from mirrored feed (is_platnium_premium).
 */
export function hasValidActiveUntilDate(job: Job): job is Omit<Job, 'active_until'> & { active_until: string } {
    return !!job.active_until && !isFromMirroredFeed(job);
}

export function areCandidatesAccessible(job: Job): boolean {
    return isActive(job) || isArchived(job);
}

export function isComplete(job: Job): boolean {
    return job.is_complete;
}

export function hasExpiringCandidates(job: Job) {
    return job.has_expiring_candidates;
}

export function isJobExperience(value: string): value is JobExperience {
    return includes(EXPERIENCES, value);
}

/* Computed */

export function createDraftJob(recruiter?: Recruiter): Job {
    return {
        id: -1,
        created: null,
        provider: '',
        state: 'draft',

        is_complete: false,

        is_premium: true,
        is_platinum_premium: false,

        url: '',
        static_url: '',
        preview_url: null,

        active_until: null,
        product: null,

        ad_language: 'de',

        title: '',
        company: recruiter?.company ?? '',

        company_logo_url: recruiter?.company_logo_file?.original ?? '',
        company_logo_original: recruiter?.company_logo_file?.original ?? '',
        company_logo: recruiter?.company_logo_file ?? {
            original: '',
            medium: ''
        },

        about_us: recruiter?.about_us ?? {
            title: '',
            body: ''
        },

        background_url: recruiter?.background_file?.original ?? '',
        background_original: recruiter?.background_file?.original ?? '',
        background: recruiter?.background_file ?? {
            original: '',
            medium: '',
            large: ''
        },

        gallery_images: (recruiter?.gallery_images ?? []).map(
            (recruiterGalleryImage: RecruiterGalleryImage): JobGalleryImage => ({
                file: recruiterGalleryImage.file,
                url: recruiterGalleryImage.file,
                order: recruiterGalleryImage.order
            })
        ),

        location: {
            raw_source: '',
            latitude: null,
            longitude: null,
            country: '',
            region: '',
            locality: '',
            postal_code: '',
            street_address: ''
        },
        remote_possible: false,

        is_startup: false,
        functions: [],
        career_statutes: [],
        experiences: [],
        worktypes: [],
        sectors: [],
        tasks: [],
        talents: [],
        skills: [],
        languages: [],

        hiring_budget: null,
        start_date: null,

        benefits: '',

        has_expiring_candidates: false,

        statistics: {
            candidates: 0,
            candidates_new: 0,
            candidates_locked: 0,
            candidates_not_interesting: 0,
            candidates_interesting: 0,
            candidates_phone_interview: 0,
            candidates_interview: 0,
            candidates_hired: 0,
            candidates_deleted: 0,
            candidates_contacted: 0,
            candidates_forwarded: 0,
            messages_unread_by_recruiter: 0
        }
    };
}

export function getCleanedCopyableJob(job: Job): Partial<Job> {
    const cleanedJob = omit(job, [
        'id',
        'created',
        'state',

        'is_platinum_premium',

        'active_until',

        'background_url',
        'background_original',
        'background',

        'company_logo_url',
        'company_logo_original',
        'company_logo',

        'gallery_images',

        'about_us',

        'url',
        'static_url'
    ]);

    return cleanedJob;
}

export function calculateNotDeletedCandidatesCount(statistics: Job['statistics']): number {
    const candidatesCount = statistics.candidates;
    const deletedCandidatesCount = statistics.candidates_deleted;

    return candidatesCount - deletedCandidatesCount;
}

export function mapGeocoderResultToJobLocation(geocoderResult: GeocoderResult): JobLocation {
    const { longitude, latitude } = getCoordinatesFromGeocoderResult(geocoderResult);

    const country = getCountryCodeFromGeocoderResult(geocoderResult);
    const region = getRegionFromGeocoderResult(geocoderResult);
    const locality = getLocalityFromGeocoderResult(geocoderResult);
    const postalCode = getPostalCodeFromGeocoderResult(geocoderResult);
    const streetName = getStreetNameFromGeocoderResult(geocoderResult);
    const streetNumber = getStreetNumberFromGeocoderResult(geocoderResult);

    const rawSource = geocoderResult.formatted_address;

    return {
        longitude,
        latitude,
        raw_source: rawSource,
        country: country ?? '',
        region: region ?? '',
        locality: locality ?? '',
        postal_code: postalCode ?? '',
        street_address: [streetName ?? '', streetNumber ?? ''].join(' ').trim()
    };
}

export type ExperienceLevel = 'entry-level' | 'professional' | 'lead';
type ExperienceLevelMap = {
    [key in JobExperience]: {
        level: ExperienceLevel | null;
        condition?: (experience: JobExperience, experiences: JobExperience[]) => boolean;
    };
};

const EXPERIENCE_LEVEL_MAP: ExperienceLevelMap = {
    'without-experience': {
        level: 'entry-level'
    },
    'with-experience': {
        level: 'professional'
    },
    'without-staff-responsibility': {
        level: null
    },
    'with-staff-responsibility': {
        level: 'lead',
        condition: (experience, experiences) => {
            return experiences.includes('without-experience') || experiences.includes('with-experience');
        }
    }
};

export function mapExperiencesToExperienceLevels(experiences: JobExperience[]) {
    const experiencesLevels: ExperienceLevel[] = [];

    for (const e of experiences) {
        const levelSpec = EXPERIENCE_LEVEL_MAP[e];

        if (typeof levelSpec.condition === 'function' && !levelSpec.condition(e, experiences)) {
            continue;
        }

        if (!levelSpec.level) {
            continue;
        }

        experiencesLevels.push(levelSpec.level);
    }

    return experiencesLevels;
}

const BENFITS_ITEM_PREFIX_PATTERN = /^\s*(-\>|>|-|–|—|\*|•|·)?\s*/;

export function convertBenefitsTextToItems(benefits: Job['benefits']): string[] {
    return benefits
        .split('\n')
        .map((line) => {
            return line.replace(BENFITS_ITEM_PREFIX_PATTERN, '').trim();
        })
        .filter((line) => {
            return !!line.length;
        });
}
