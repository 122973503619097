import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { PackageSubset } from '../../modules/packages/types';
import ProductType from '../ProductType';

import classes from './PackageType.module.scss';

export type PackageTypeProps = {
    pkg: PackageSubset<'product' | 'performance_days'>;
    wrap?: boolean;
};

function PackageType({ pkg, wrap = false }: PackageTypeProps) {
    return (
        <span
            className={classNames(classes.root, {
                [classes.wrap]: wrap
            })}
        >
            <span className={classes.part}>
                <ProductType product={pkg.product} />
            </span>
            <span className="sr-only">{' – '}</span>
            <span className={classes.part}>
                <FormattedMessage
                    id="PACKAGE_TYPE.PEFORMANCE_DAYS_LABEL"
                    values={{
                        performanceDays: pkg.performance_days
                    }}
                />
            </span>
        </span>
    );
}

export default PackageType;
