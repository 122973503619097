import React from 'react';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Form, useFormState, FormRenderProps, useForm } from 'react-final-form';
import { ERROR_JOB_PROPERTIES_NOT_EDITABLE } from '../../services/api/constants';
import { hasErrorWithCode } from '../../services/api/utils';
import { Job } from '../../modules/jobs/types';
import { isActive, isArchived, isDraft, isNew, isPropertyEditable } from '../../modules/jobs/utils';
import { Recruiter } from '../../modules/recruiters/types';
import { useField } from '../../utils/form/react-final-form';
import { poll } from '../../utils/promise';
import { useIsOpen } from '../../hooks/useIsOpen';
import JobFormPageActions from './JobFormPageActions';
import JobFormPageAlerts from './JobFormPageAlerts';
import JobFormPageLayout from './JobFormPageLayout';
import JobFormAdDetailsFormFieldset from './JobFormAdDetailsFormFieldset';
import JobFormBenefitsFormFieldset from './JobFormBenefitsFormFieldset';
import JobFormCompanyDetailsFormFieldset from './JobFormCompanyDetailsFormFieldset';
import JobFormConfirmGotoSettingsModal from './JobFormConfirmGotoSettingsModal';
import JobFormLocationFormFieldset from './JobFormLocationFormFieldset';
import JobFormRequirementsFormFieldset from './JobFormRequirementsFormFieldset';
import JobFormTasksFormFieldset from './JobFormTasksFormFieldset';
import JobFormWorkConditionsFormFieldset from './JobFormWorkConditionsFormFieldset';
import {
    JobFormPageJobPartial,
    JobFormPageSubmissionType,
    JobFormPageValidationLevel,
    JobFormPageValues,
    JOB_FORM_ERROR_FIELDS_NOT_EDITABLE,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT,
    JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL,
    JOB_FORM_PAGE_VALIDATION_LEVEL_FULL
} from './JobFormPage.constants';
import {
    JobFormSubmissionError,
    makeJobPartialFromValues,
    makeValuesFromJobPartial,
    scrollToFirstInvalidField
} from './JobFormPage.utils';

export type JobFormPageProps = {
    recruiter: Recruiter;
    job: Job;

    isParsed?: boolean;
    isUpgraded?: boolean;
    initialValidation: boolean;
    onSubmit: (job: JobFormPageJobPartial, submissionType: JobFormPageSubmissionType | null) => Promise<void>;
    onCancel: () => void;
    onOpenSettings: () => void;
};

function JobFormPage({
    recruiter,
    job,
    isParsed = false,
    isUpgraded = false,
    initialValidation,
    onSubmit,
    onCancel,
    onOpenSettings
}: JobFormPageProps) {
    const initialValues: JobFormPageValues = React.useMemo(() => {
        return makeValuesFromJobPartial(job, initialValidation);
    }, []);

    const handleSubmit = async (values: JobFormPageValues) => {
        const submissionType = values['_submission-type'];

        const jobPartial = makeJobPartialFromValues(values);

        try {
            await onSubmit(jobPartial, submissionType);
        } catch (error) {
            if (error instanceof JobFormSubmissionError) {
                let formError: string | null = 'unknown';

                if (!!error.errors && hasErrorWithCode(error.errors, ERROR_JOB_PROPERTIES_NOT_EDITABLE)) {
                    formError = JOB_FORM_ERROR_FIELDS_NOT_EDITABLE;
                }

                return {
                    [FORM_ERROR]: formError
                };
            }

            throw error;
        }
    };

    return (
        <Form<JobFormPageValues> mutators={{ ...arrayMutators }} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <JobFormPageInner
                    recruiter={recruiter}
                    job={job}
                    isParsed={isParsed}
                    isUpgraded={isUpgraded}
                    initialValidation={initialValidation}
                    handleSubmit={handleSubmit}
                    onOpenSettings={onOpenSettings}
                    onCancel={onCancel}
                />
            )}
        </Form>
    );
}

type JobFormPageInnerProps = {
    recruiter: Recruiter;
    job: Job;

    isParsed: boolean;
    isUpgraded: boolean;
    initialValidation: boolean;

    handleSubmit: FormRenderProps<JobFormPageValues>['handleSubmit'];

    onOpenSettings: () => void;
    onCancel: () => void;
};

function JobFormPageInner({
    recruiter,
    job,

    isParsed,
    isUpgraded,
    initialValidation,

    handleSubmit,

    onOpenSettings,
    onCancel
}: JobFormPageInnerProps) {
    const form = useForm<JobFormPageValues>();

    const validationLevelField = useField<JobFormPageValidationLevel, HTMLElement>('_validation-level', {
        initialValue: JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL
    });
    const submissionTypeField = useField<JobFormPageSubmissionType | null, HTMLElement>('_submission-type', {
        initialValue: JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE
    });
    const submissionType = submissionTypeField.input.value;

    const onClickSubmitButton = async (submissionType: JobFormPageSubmissionType | null) => {
        validationLevelField.input.onChange(
            isValidationRequired(job, submissionType)
                ? JOB_FORM_PAGE_VALIDATION_LEVEL_FULL
                : JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL
        );
        submissionTypeField.input.onChange(submissionType);

        // We wait until the validation is completed and scroll to the first invalid field
        await poll(() => {
            if (form.getState().validating) {
                throw new Error('Form is validating');
            }
        });
        scrollToFirstInvalidField(form);
    };

    const { invalid, submitting, submitFailed, submitError } = useFormState<JobFormPageValues>({
        subscription: {
            values: true,
            invalid: true,
            submitting: true,
            submitFailed: true,
            submitError: true
        }
    });

    const gotoSettingsModal = useIsOpen();
    const handleCancelGotoSettings = () => {
        gotoSettingsModal.close();
    };

    const {
        initialShowInformationThatJobFunctionsLengthChanged,
        initialShowInformationThatJobWasParsedBefore,
        initialShowInformationThatActiveJobIsInEditMode,
        initialShowInformationThatJobWasUpgradedBefore,
        initialShowErrorRequiredAlert
    } = React.useMemo(() => {
        const initialShowInformationThatJobFunctionsLengthChanged = job.functions.length > 1;

        let initialShowInformationThatJobWasParsedBefore = false;
        let initialShowInformationThatActiveJobIsInEditMode = false;
        let initialShowInformationThatJobWasUpgradedBefore = false;

        if (isDraft(job) && isParsed === true) {
            initialShowInformationThatJobWasParsedBefore = true;
        } else if (isDraft(job) && isUpgraded === true) {
            initialShowInformationThatJobWasUpgradedBefore = true;
        } else if (isActive(job)) {
            initialShowInformationThatActiveJobIsInEditMode = true;
        }

        const initialShowErrorRequiredAlert = initialValidation && !job.is_complete;

        return {
            initialShowInformationThatJobFunctionsLengthChanged,
            initialShowInformationThatJobWasParsedBefore,
            initialShowInformationThatActiveJobIsInEditMode,
            initialShowInformationThatJobWasUpgradedBefore,
            initialShowErrorRequiredAlert
        };
    }, []);

    const errorRequiredAlert = useIsOpen(initialShowErrorRequiredAlert);
    const hadFieldsNotAllowedToBeEditedSinceLastSubmitRef = React.useRef(false);
    const errorFieldsNotAllowedToBeEdited = useIsOpen(false);

    const showErrorFieldsNotAllowedToBeEditedIfNeeded = () => {
        // We've already shown the error it was shown. We don't show it again until the next submission.
        if (hadFieldsNotAllowedToBeEditedSinceLastSubmitRef.current) {
            return;
        }

        hadFieldsNotAllowedToBeEditedSinceLastSubmitRef.current = true;
        errorFieldsNotAllowedToBeEdited.open();
    };

    React.useEffect(() => {
        if (submitFailed) {
            scrollToFirstInvalidField(form);
        }
    }, [submitFailed]);

    React.useEffect(() => {
        if (submitError === JOB_FORM_ERROR_FIELDS_NOT_EDITABLE) {
            showErrorFieldsNotAllowedToBeEditedIfNeeded();
        }
    }, [submitError]);

    const handleConfirmGotoSettings = async () => {
        if (!gotoSettingsModal.isOpen) {
            return;
        }

        if (!isActive(job)) {
            onClickSubmitButton(null);
            await handleSubmit();

            if (form.getState().submitFailed) {
                gotoSettingsModal.close();
                return;
            }
        }

        gotoSettingsModal.close();
        onOpenSettings();
    };

    const onClickCallToAction = () => {
        gotoSettingsModal.open();
    };

    console.log({ job, title: isPropertyEditable(job, 'title') });

    return (
        <React.Fragment>
            <JobFormPageLayout>
                <form onSubmit={handleSubmit}>
                    <JobFormPageAlerts
                        initialShowInformationThatJobFunctionsLengthChanged={
                            initialShowInformationThatJobFunctionsLengthChanged
                        }
                        initialShowInformationThatJobWasParsedBefore={initialShowInformationThatJobWasParsedBefore}
                        initialShowInformationThatActiveJobIsInEditMode={
                            initialShowInformationThatActiveJobIsInEditMode
                        }
                        initialShowInformationThatJobWasUpgradedBefore={initialShowInformationThatJobWasUpgradedBefore}
                        showErrorRequired={errorRequiredAlert.isOpen}
                        onCloseErrorRequired={errorRequiredAlert.close}
                        showErrorFieldsNotAllowedToBeEdited={errorFieldsNotAllowedToBeEdited.isOpen}
                        onCloseErrorFieldsNotAllowedToBeEdited={errorFieldsNotAllowedToBeEdited.close}
                    />

                    <JobFormCompanyDetailsFormFieldset
                        recruiter={recruiter}
                        isCompanyEditable={isPropertyEditable(job, 'company')}
                        onClickCallToAction={onClickCallToAction}
                    />
                    <JobFormAdDetailsFormFieldset
                        isTitleEditable={isPropertyEditable(job, 'title')}
                        isFunctionsEditable={isPropertyEditable(job, 'functions')}
                    />
                    <JobFormLocationFormFieldset isLocationEditable={isPropertyEditable(job, 'location')} />
                    <JobFormWorkConditionsFormFieldset />
                    <JobFormTasksFormFieldset />
                    <JobFormRequirementsFormFieldset />
                    <JobFormBenefitsFormFieldset />

                    <JobFormPageActions
                        job={job}
                        invalid={invalid}
                        submitting={submitting}
                        submissionType={submissionType}
                        setSubmissionType={onClickSubmitButton}
                        onCancel={onCancel}
                    />

                    <JobFormPageActions
                        fixed
                        job={job}
                        invalid={invalid}
                        submitting={submitting}
                        submissionType={submissionType}
                        setSubmissionType={onClickSubmitButton}
                        onCancel={onCancel}
                    />
                </form>
            </JobFormPageLayout>

            <JobFormConfirmGotoSettingsModal
                open={gotoSettingsModal.isOpen}
                isActiveJob={isActive(job)}
                submitting={submitting}
                onConfirm={handleConfirmGotoSettings}
                onCancel={handleCancelGotoSettings}
            />
        </React.Fragment>
    );
}

function isValidationRequired(job: Job, submissionType: JobFormPageSubmissionType | null): boolean {
    const submissionTypeIsCheckout = submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT;

    const shouldSaveAndCheckoutNewJob = isNew(job) && submissionTypeIsCheckout;
    const shouldSaveAndCheckoutDraftJob = isDraft(job) && submissionTypeIsCheckout;
    const shouldSaveAndCheckoutArchivedJob = isArchived(job) && submissionTypeIsCheckout;
    const shouldSaveActiveJob = isActive(job);

    return (
        shouldSaveAndCheckoutNewJob ||
        shouldSaveAndCheckoutDraftJob ||
        shouldSaveAndCheckoutArchivedJob ||
        shouldSaveActiveJob
    );
}

export default JobFormPage;
