import * as React from 'react';
import { companyContact, companyName, example, legalCompanyName } from '../constants';
import { createIntlEnhancer } from './utils';

const intlValues = {
    productNameUpperCase: companyName.upperCase,
    productNameLowerCase: companyName.lowerCase,

    companyContactPhone: companyContact.phone,
    companyContactEmail: companyContact.email,

    examplePhone: example.phone,

    legalCompanyName: legalCompanyName,

    // Formatting helpers
    br: <br />,
    shy: '\u00AD',
    nbsp: '\u00A0',
    b: (chunks) => <b>{chunks}</b>,
    strong: (chunks) => <strong>{chunks}</strong>
};

export const enhanceIntl = createIntlEnhancer(intlValues);
